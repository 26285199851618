import React from "react"

const Footer = () => {
    return (
        <section className="footer">
            <hr />
            <div className="container center">
                <img src="/logoGBSYS.png" alt="gbsys" border="0" />
                <p>Todos los derechos reservados 2023</p>
            </div>
        </section>
    )
}
export default Footer