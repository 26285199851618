import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { AuthContext } from '../../AuthContext';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import Welcome from '../Welcome/Welcome';
let urlAuth = process.env.REACT_APP_COGNITO_OAUTH;
let urlReactApp = process.env.REACT_APP_REDIRECT_URI;
let cliendId = process.env.REACT_APP_COGNITO_CLIENT_ID;

const Dashboard = () => {
    const { user, loggedIn, checkLoginState } = useContext(AuthContext);
    const [posts, setPosts] = useState([]);

    const serverUrl = process.env.REACT_APP_SERVER_URL;

    useEffect(
        () => {
            const fetchPosts = async () => {
                if (loggedIn === true) {
                    try {
                        const response = await axios.get(`${serverUrl}/getUserDetails`);
                        setPosts(response.data);
                    } catch (err) {
                        console.error(err);
                    }
                }
            };

            fetchPosts();
        },
        [loggedIn, serverUrl]
    );

    const handleLogout = async () => {
        try {
            await axios.post(`${serverUrl}/logout`);
            checkLoginState();
            window.location = `${urlAuth}/logout?client_id=${cliendId}&redirect_uri=${urlReactApp}&response_type=code&state=STATE&
            scope=openid`;
        } catch (err) {
            console.error(err);
        }
    };

    return (
        <div>
            <Header loguot={handleLogout} username={posts ? posts.username : ''} email={posts ? posts.email : 'User Email'}></Header>
            <div className='container-fluid principal-content mt-5'>
                <Welcome></Welcome>
            </div>
            <Footer></Footer>
        </div>

    );
};

export default Dashboard;