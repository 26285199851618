import React from "react"
import { Link } from "react-router-dom"

const Header = ({ loguot, username, email }) => {
    return (

        <div className="nav-color">
            <div className="container-xl">
                <div className="contact-info d-md-flex">
                    <p>(506)5555-55555</p>
                    <p><a href="mailto:">{email}</a></p>
                </div>
                <div className="mb-5" >
                    <nav className="navbar navbar-expand-lg bg-white bg-body-tertiary">
                        <div className="container-fluid">
                            <a className="navbar-brand" href="/"><img className="img-fluid" src="/logo2.png" /></a>
                            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon"></span>
                            </button>
                            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                                    <li className="nav-item" ><a className="nav-link" href='/'>Inicio</a></li>
                                    <li className="nav-item" > <a className="nav-link" href='/'>Principal</a></li>
                                    <li className="nav-item" ><a className="nav-link" href='/'>Pagos</a></li>
                                    <li className="nav-item" ><a className="nav-link" href='/'>Administración</a></li>
                                    <li className="nav-item" ><a className="nav-link" href='/'>Mantenimientos</a></li>
                                    <li className="nav-item" ><a className="nav-link" href='/'>Seguridad</a></li>
                                    <li className="nav-item dropdown">
                                        <a className="nav-link dropdown-toggle" href="/" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            {username}
                                        </a>
                                        <ul className="dropdown-menu">
                                            <li><button className="dropdown-item" onClick={loguot}>Cerrar Sesión</button></li>
                                            <li><a className="dropdown-item" href="/">Ver Perfil</a></li>

                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </nav>
                </div>

            </div>
        </div>
    )
}

export default Header;