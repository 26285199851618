import React from "react";
import Home from "./components/Home/Home";
import "./App.css";
import { BrowserRouter as Router, Route } from 'react-router-dom';
import axios from 'axios';
import { AuthContextProvider } from "./AuthContext";
import "bootstrap/dist/css/bootstrap.min.css";

axios.defaults.withCredentials = true;

function App() {
    return (
        <div className="App">
            <AuthContextProvider>
                <Router>
                    <Route path="/" component={Home} />
                </Router>
            </AuthContextProvider>
        </div>
    );
}

export default App;

