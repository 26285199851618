// Home.js

import { Route } from 'react-router-dom';
import { AuthContext } from '../../AuthContext';
import Dashboard from '../Dashboard/Dashboard';
import LoginPage from '../LoginPage/LoginPage';
import React, { useContext, useEffect } from 'react';
import axios from 'axios';
import { useLocation } from "react-router-dom";
const Home = () => {
    const { loggedIn } = useContext(AuthContext);
    const pathname = window.location.pathname;
    const serverUrl = process.env.REACT_APP_SERVER_URL;
    const location = useLocation()
    const params = new URLSearchParams(location.search)

    useEffect(
        () => {
            const fetchPosts = async () => {
                if (pathname === '/login') {
                    try {
                        const response = await axios.post(`${serverUrl}/login`, {
                            code: params.get("code"),
                            state: params.get("state")
                        });
                        const { url } = response.data;
                        window.location.assign(url);
                    } catch (err) {
                        console.error(err);
                    }
                }
            };
            fetchPosts();
        },
        []
    );
    return (
        <Route path="/" component={loggedIn === true ? Dashboard : LoginPage} />
    );
};
export default Home;
