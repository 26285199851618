
const Welcome = () => {
    return (
        <section className="service" id="service">
        <div className="container service-section">
            <div className="row">
                <div className="col imageservice-section">
                    <div className="position-relative">
                        <div className="top-50 start-50">
                            <img src="https://catedraconectividadunlp.com/wp-content/uploads/2021/03/mapa-conectividad-768x768.png" alt="img-service" border="0" />
                        </div>
                    </div>
                </div>
                <div className="col right-service-section">
                    <h1 className="heading-service">Bienvenidos a AdminConectividad</h1>
                    <p className="detail-heading detail-heading-service-style">El sistema de AdminConectividad es una herramienta que le permite gestionar el proceso de recaudación externa en linea</p>
                    <hr />
                    <h5 className="heading-service">Información de facturación</h5>
                    <p className="detail-heading detail-heading-service-style">Cantidad de Facturadores: 4</p>
                    <hr />

                    <ol className="service-list">
                        <li className="list-detail">
                            <i className="bi bi-check-circle"></i>
                            <p>Horario: 8:00am - 15:00pm</p>
                        </li>
                        <li className="list-detail">
                            <i className="bi bi-check-circle"></i>
                            <p>Servicio al cliente: Soporte GBSYS S.A</p>
                        </li>
                        <li className="list-detail">
                            <i className="bi bi-check-circle"></i>
                            <p>Teléfono: 2286-0101</p>
                        </li>
                        <li className="list-detail">
                            <i className="bi bi-check-circle"></i>
                            <p>Horario de Cierrre: 23:45:00pm</p>
                        </li>
                    </ol>
                </div>
            </div>
        </div>
    </section>
    );
};

export default Welcome;