import React from 'react';
import axios from 'axios';

const serverUrl = process.env.REACT_APP_SERVER_URL;

const LoginPage = () => {
    const handleLogin = async () => {
        debugger
        try {
            const response = await axios.get(`${serverUrl}/AuthPage`);
            const { url } = response.data;
            window.location.assign(url);
        } catch (err) {
            console.error(err);
        }
    };

    return (
          <div className="login-container">
          <div className="login">
            <div className="pt-1 mb-4">
              <input type="button"
                onClick={handleLogin}
                value={'Iniciar Sesión'}
                className='btn btn-primary'
              />
            </div>
            </div>
          </div>
    );
};

export default LoginPage;